window.CardHoverOverlay = cardHoverOverlayId => {
    return {
        init() {
            if (document.querySelector(`#${cardHoverOverlayId} .wp-block-button__link`)) {
            document.querySelector(`#${cardHoverOverlayId} .wp-block-button__link`).addEventListener('mouseenter', () => {
                document.querySelector(`#${cardHoverOverlayId}`).classList.add('CardHoverOverlay--active');
                
            });
            document.querySelector(`#${cardHoverOverlayId} .wp-block-button__link`).addEventListener('mouseleave', () => {
                document.querySelector(`#${cardHoverOverlayId}`).classList.remove('CardHoverOverlay--active');
                
            });
        };
        },
    };
};