window.FourColumnsOrMobileSlider = cardSliderSectionId => {
	return {
		lastActiveSelector: 0,
		allSelectors: document.querySelectorAll(`#${cardSliderSectionId} .sliderSelector`),
		allCards: document.querySelectorAll(`#${cardSliderSectionId} .sliderCard`),
		init() {
			this.allSelectors.forEach((button, index) => {
				button.addEventListener('click', () => {
					this.allCards.forEach(item => {
						item.classList.add('hidden');
					});
					this.allCards[index].classList.remove('hidden');

					this.updateSelectors(index);
				});
			});
		},
		swipedRightHandler() {
			let nextActiveSelector;
			if (this.lastActiveSelector === 0) {
				nextActiveSelector = this.allSelectors.length - 1;
			} else {
				nextActiveSelector = this.lastActiveSelector - 1;
			}

			this.allCards.forEach(item => {
				item.classList.add('hidden');
			});
			this.allCards[nextActiveSelector].classList.remove('hidden');

			this.updateSelectors(nextActiveSelector);
		},

		swipedLeftHandler() {
			let nextActiveSelector;
			if (this.lastActiveSelector === this.allSelectors.length - 1) {
				nextActiveSelector = 0;
			} else {
				nextActiveSelector = this.lastActiveSelector + 1;
			}

			this.allCards.forEach(item => {
				item.classList.add('hidden');
			});
			this.allCards[nextActiveSelector].classList.remove('hidden');

			this.updateSelectors(nextActiveSelector);
		},

		updateSelectors(number) {
			this.allSelectors[this.lastActiveSelector].classList.remove('bg-black');
			this.allSelectors[this.lastActiveSelector].classList.add('bg-yanceygrey');
			this.allSelectors[number].classList.add('bg-black');
			this.allSelectors[number].classList.remove('bg-yanceygrey');
			this.lastActiveSelector = number;
		},
	};
};
